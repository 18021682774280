/**
 * dropdown
 */

.jplist-dd {
  width: 200px;
  background: #efefef;
  color: #575757;
  font-size: 13px;
  border-radius: 2px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}

.jplist-dd-panel {
  text-align: center;
  font-size: 15px;
  padding: 5px 7px;
  transition: color 0.5s;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.jplist-dd-panel::after {
  content: '\25BC';
  display: inline-block;
  float: right;
  transition: transform 0.5s;
  color: #a5a5a5;
}

.jplist-dd-panel.jplist-dd-opened::after {
  transform: rotate(180deg);
}

.jplist-dd-panel:hover {
  color: #000;
}

.jplist-dd-content {
  display: none;
}

.jplist-dd-content.jplist-dd-opened {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  top: 100%;
  left: 0;
  box-shadow: 3px 5px 5px #e0e0e0;
  position: absolute;
  background: #f5f5f5;
  box-sizing: border-box;
  z-index: 1000;
}

.jplist-dd-item {
  border-bottom: 1px dotted #ccc;
  box-sizing: border-box;
  padding: 5px 15px;
  transition: background-color 0.5s;
}

.jplist-dd-item:hover {
  background-color: #fff;
  color: #000;
}

.jplist-dd-item:last-child {
  border: 0;
}/**
 * horizontal
 */

.jplist-slider {
  width: 300px;
  height: 20px;
  background: #efefef;
  margin-right: 15px;
  margin-bottom: 35px;
  border-radius: 2px;
  position: relative;
  box-sizing: border-box;
}

.jplist-slider::before {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background: blue;
  position: absolute;
  z-index: 100;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.jplist-slider-holder-1,
.jplist-slider-holder-2 {
  width: 10px;
  height: 100%;
  border-radius: 2px;
  display: block;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, 0);
  z-index: 200;
  transition: 0.3s background-color;
  padding: 0;
  margin: 0;
  font-size: 0;
  line-height: 0;
}

.jplist-slider-holder-1 {
  background: #000;
}

.jplist-slider-holder-1:active {
  background: #9f35ff;
}

.jplist-slider-holder-2 {
  background: #822121;
}

.jplist-slider-holder-2:active {
  background: #ff2888;
}

.jplist-slider-range {
  width: 0;
  height: 20px;
  background: #ccc;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

/**
 * vertical
 */

.jplist-slider.jplist-slider-vertical {
  width: 20px;
  height: 300px;
}

.jplist-slider.jplist-slider-vertical::before {
  width: 3px;
  height: 100%;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
}

.jplist-slider.jplist-slider-vertical .jplist-slider-holder-1,
.jplist-slider.jplist-slider-vertical .jplist-slider-holder-2 {
  height: 10px;
  width: 100%;
  left: 0;
  top: 0;
  transform: translate(0, -50%);
}

.jplist-slider.jplist-slider-vertical .jplist-slider-range {
  width: 100%;
  height: 0;
}
/*# sourceMappingURL=jplist.styles.css.map*/